<template lang="pug">
.w-100
  navs
  Alert

  .home-cover
    //- b-img.wa-icon(src='@/assets/images/icons/wa.png', height='70')
    .back-opacy
      b-container.d-flex.align-items-center.flex-column.justify-content-center.h-100.text-white.text-shadow.text-center
        h1.font-weight-bold Expertos en materiales eléctricos
        .w-75
          p.subtitle-font-size Somos una empresa líder y experta en materiales eléctricos con más de 20 años de experiencia
        b-button(variant='primary', :to='{ name: "Catalog" }') Ver productos

  .section
    b-container(fluid)
      b-row.align-items-center
        b-col(md='7')
          b-card.mb-0
            p.font-weight-bold Acerca de Matelco
            p.
              Somos una empresa dedicada a la distribución de material y equipo eléctrico de marcas de
              reconocido prestigio nacional e internacional, estamos presentes en el mercado mexicano
              desde el año 1991.
              <br/> <br/>
              Distribuimos material électrico a toda la republica a sectores industriales, gobierno, subdistribuidores, contratistas e
              instaladores. Contamos con la infraestructura y existencias necesarias para poder ofrecer a
              nuestros clientes un servicio calidad a precio justo.
              <br/> <br/>
              Nuestra encomienda, es ser una empresa en constante innovación buscando siempre nuevas
              alternativas en beneficio de nuestros clientes.

        b-col(md='5')
          b-img(src='@/assets/images/2.png', fluid)

  .section.text-center
    b-container
      h3.font-weight-bold.mb-5 Nuestros servicios
      b-row.align-items-stretch
        b-col(v-for='(service, index) in services', :key='index')
          b-card.text-center.mb-0.h-100.card-color
            b-img.mb-3.size-img(:src='require(`@/assets/images/icons/${service.icon}`)')
            p.font-weight-bold {{ service.title }}
            p.mb-0.card-color-text {{ service.text }}

  h3.font-weight-bold.carousel-title Nuestras marcas

  b-carousel.carousel(v-model='slide', :interval='5000', indicators, background='transparent', style='text-shadow: 1px 1px 2px #333', @sliding-start='onSlideStart', @sliding-end='onSlideEnd')
    b-carousel-slide.carousel-slide(v-for='(brand, index) in brands', :key='index')
      .carousel-back
        b-img(:src='require(`@/assets/images/back-brands/${brand}`)')

      .carousel-logo 
        b-img.logo(:src='require(`@/assets/images/brands/${brand}`)')

  contact-form#mail

  footer-component
</template>

<script>
import ContactForm from '@/components/ContactForm.vue'
import FooterComponent from '@/components/Footer.vue'
import Navs from '@/components/Navs.vue'
import Alert from '@/components/Alert.vue'

export default {
  name: 'Home',
  components: { ContactForm, FooterComponent, Navs, Alert },
  data() {
    return {
      slide: 0,
      sliding: null,
      services: [
        {
          icon: 'house.png',
          title: 'Atención Personalizada',
          text: 'Nuestro personal capacitado le brindará las soluciones que usted necesita para sus diferentes proyectos y negocios a realizar.',
        },
        {
          icon: 'clock.png',
          title: 'Embarcamos a toda la Républica Mexicana',
          text: 'Enviamos pedidos a todo México, con todas las medidas para que su pedido llegue en tiempo y forma.',
        },
        {
          icon: 'customer-service.png',
          title: 'Asesoria técnica',
          text: 'Contamos con un grupo de personas altamente capacitadas en cuestiones técnicas, industriales y de alumbrado urbano.',
        },
      ],
      brands: ['appleton.png', 'conteesa.png', 'emerson.png', 'hubbel.png', 'luminance.png', 'nita.png', 'philco.png', 'syltech.png', 'tork.png'],
    }
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/mixins/_breakpoints.scss';

.size-img {
  width: 30px;
}

.card-color {
  background-color: #2a3f81;

  p {
    color: white;
  }

  .card-color-text {
    color: rgb(226, 226, 226);
  }
}

.back-opacy {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.back-image {
  width: 100%;
  height: 100%;
}

.carousel-title {
  position: relative;
  top: 60px;
  z-index: 10;
  color: white;
  text-align: center;
}

.carousel {
  z-index: 0;
  margin: 0 !important;
  text-align: center;

  .carousel-slide {
    height: 250px;

    .carousel-back {
      display: flex;
      width: 100vw;
      height: 250px;
      position: absolute;
      right: -21.4%;
      top: -475%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .carousel-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 250px;
      position: absolute;
      right: -21.4%;
      top: -475%;
      z-index: 10;
      background-color: rgba(0, 0, 0, 0.5);

      img {
        width: auto;
        height: 120px;
      }
    }
  }
}

.home-cover {
  position: relative;
  width: 100%;
  height: 80vh;
  background-image: url('~@/assets/images/home-bg.jpg');
  background-position: center;
  background-size: cover;
  margin-top: 106px;

  .wa-icon {
    position: absolute;
    bottom: -30px;
    right: 50px;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 112px;
    height: 60vh;

    .subtitle-font-size {
      font-size: 1.5em;
    }
  }
}

.section {
  padding-top: 1rem;
  padding-bottom: 1rem;

  @include media-breakpoint-up(lg) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.overlap-left-80 {
  @include media-breakpoint-up(md) {
    margin-left: -80px;
  }
}

.brands-bg {
  width: 100%;
  background-position: center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
