<template lang="pug">
.alert_content
  b-alert(v-if='message', show, dismissible, :variant='type') {{ message }}
</template>

<script>
export default {
  name: 'Alert',
  data() {
    return {
      type: null,
      message: null,
    }
  },
  methods: {
    showAlert() {
      setTimeout(() => {
        this.type = null
        this.message = null
      }, 5000)
    },
  },
  watch: {
    message(value) {
      if (value) {
        this.showAlert()
      }
    },
  },
  mounted() {
    this.$alert.$on('alert', (params) => {
      this.type = params.type
      this.message = params.message
    })
  },
}
</script>

<style lang="scss" scoped>
.alert_content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  z-index: 10;
  top: 114px;
  right: 0px;
}
</style>